html, body {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
  padding: 20px; /* Adjust as necessary */
}

footer {
  background-color: #000;
  color: white;
  text-align: center;
  padding: 10px 0;
  width: 100%;
}
